import { useEffect, useState } from "react";
import { BackIcon, RubbleIcon, UserIcon } from "../Icons";
import Select from "../select/select";
import { generalBankList, trafficDetailsScore } from "../../common/constants";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../../common/api";
import { toast } from "react-toastify";

const ModalBankEdit = ({ active, setActive, dataBank, refetch }) => {
  useGlobalScrollLock(active);
  configure({ axios: $authHost });

  const banksTypes = [];
  const trafficTypes = [];
  Object.keys(generalBankList).map((key) =>
    banksTypes.push({
      id: key,
      label: generalBankList[key],
    })
  );
  Object.keys(trafficDetailsScore).map((key) =>
    trafficTypes.push({
      id: key,
      label: trafficDetailsScore[key],
    })
  );
  const [bank, setBank] = useState({
    id: null,
    label: "Выберите",
    icon: null,
  });
  const [tier, setTier] = useState({
    id: null,
    label: "Выберите качество трафика",
    icon: null,
  });

  const [value, setValue] = useState({
    fio: "",
    bankId: "",
    minAmount: "",
    maxAmount: "",
    totalAmount: "",
    usedTotalAmount: "",
    sbpAvailable: "",
    availableBank: "",
    cardAvailable: "",
    enablePush: "",
  });

  const handleSbp = () => {
    setValue({ ...value, sbpAvailable: value.sbpAvailable === 1 ? 0 : 1 });
  };
  const handlePush = () => {
    setValue({ ...value, enablePush: value.enablePush === 1 ? 0 : 1 });
  };
  const handleCard = () => {
    setValue({ ...value, cardAvailable: value.cardAvailable === 1 ? 0 : 1 });
  };

  const handleAvailable = () => {
    setValue({ ...value, availableBank: value.availableBank === 1 ? 0 : 1 });
  };
  useEffect(() => {
    if (dataBank) {
      setBank(banksTypes.find((item) => item.id === String(dataBank?.bank)));
      setTier(trafficTypes.find((item) => item.id === String(dataBank?.tier)));
      setValue({
        fio: dataBank?.fio,
        minAmount: dataBank?.min_amount,
        maxAmount: dataBank?.max_amount,
        totalAmount: dataBank?.total_amount,
        usedTotalAmount: dataBank?.used_total_amount,
        sbpAvailable: dataBank?.sbp_available,
        availableBank: dataBank?.available,
        cardAvailable: dataBank?.card_available,
        enablePush: dataBank?.enable_push,
      });
    }
  }, [dataBank]);

  const [{ loading }, executePost] = useAxios(
    {
      url: `/trader/bank/profiles/edit`,
      method: "POST",
    },
    axiosConfig
  );
  const handleEdit = () => {
    executePost({
      data: {
        bankId: dataBank.id,
        fio: value.fio,
        bank: bank.id,
        min_amount: value.minAmount,
        max_amount: value.maxAmount,
        total_amount: value.totalAmount,
        used_total_amount: value.usedTotalAmount,
        sbp_available: value.sbpAvailable,
        available: value.availableBank,
        enable_push: value.enablePush,
        card_available: value.cardAvailable,
        tier: tier.id,
      },
    })
      .then((data) => {
        toast.success("Успешно");
        refetch();
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };

  return (
    <div className={active ? "popup open" : "popup"}>
      <div className="popup__body" onClick={() => setActive(false)}>
        <div
          className={`popup__content popup__device ${
            loading ? "__loading" : ""
          }`}
          onClick={(e) => e.stopPropagation(e)}
        >
          <div className="popup__header">
            <span>Редактировать банк</span>
            <button onClick={() => setActive(false)}>
              <BackIcon />
            </button>
          </div>
          <div className="device__tab">
            <div className="input-block">
              <span>ФИО</span>
              <div className="input-container">
                <input
                  value={value.fio}
                  type="text"
                  autoComplete="off"
                  placeholder="Иванов Иван Иваныч"
                  onChange={(e) => setValue({ ...value, fio: e.target.value })}
                />
                <UserIcon />
              </div>
            </div>
            <div className="input-block">
              <span>Банк</span>
              <div className="input-container">
                <Select value={bank} setValue={setBank} data={banksTypes} />
              </div>
            </div>
            <div className="input-block">
              <span>Мин платеж</span>
              <div className="input-container">
                <input
                  value={value.minAmount}
                  type="number"
                  autoComplete="off"
                  placeholder="100"
                  onChange={(e) =>
                    setValue({ ...value, minAmount: e.target.value })
                  }
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <div className="input-block">
              <span>Макс платеж</span>
              <div className="input-container">
                <input
                  value={value.maxAmount}
                  type="number"
                  autoComplete="off"
                  placeholder="10000"
                  onChange={(e) =>
                    setValue({ ...value, maxAmount: e.target.value })
                  }
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <div className="input-block">
              <span>Максимум на карту</span>
              <div className="input-container">
                <input
                  value={value.totalAmount}
                  type="number"
                  autoComplete="off"
                  placeholder="10000"
                  onChange={(e) =>
                    setValue({ ...value, totalAmount: e.target.value })
                  }
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <div className="input-block">
              <span>Использовано на карте</span>
              <div className="input-container">
                <input
                  value={value.usedTotalAmount}
                  type="number"
                  autoComplete="off"
                  placeholder="10000"
                  onChange={(e) =>
                    setValue({ ...value, usedTotalAmount: e.target.value })
                  }
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <div className="input-block">
              <span>Трафик</span>
              <div className="input-container">
                <Select value={tier} setValue={setTier} data={trafficTypes} />
              </div>
            </div>
            <div className="withdraw__switch switch-main">
              <span>Включен сбп? </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={value.sbpAvailable}
                  onChange={handleSbp}
                />
                <span></span>
              </label>
            </div>
            <div className="withdraw__switch switch-main">
              <span>Пуши включены ловим их? </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={value.enablePush}
                  onChange={handlePush}
                />
                <span></span>
              </label>
            </div>
            <div className="withdraw__switch switch-main">
              <span>Карты доступны на этом банке или только сбп? </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={value.cardAvailable}
                  onChange={handleCard}
                />
                <span></span>
              </label>
            </div>
            <div className="withdraw__switch switch-main">
              <span>Доступность </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={value.availableBank}
                  onChange={handleAvailable}
                />
                <span></span>
              </label>
            </div>
          </div>

          <div className="step__navigation">
            <button className="deviceBtn" onClick={handleEdit}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBankEdit;
