import { useEffect, useState } from "react";
import { BackIcon, CardMethodIcon, RubbleIcon } from "../Icons";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../../common/api";
import { toast } from "react-toastify";

const ModalRequisiteEdit = ({ active, setActive, refetch, dataRequisite }) => {
  useGlobalScrollLock(active);
  configure({ axios: $authHost });

  const [value, setValue] = useState({
    pan: "",
    totalAmountReq: "",
    usedTotalAmountReq: "",
    isMain: "",
    availableReq: "",
  });

  const handleMain = () => {
    setValue({ ...value, isMain: value.isMain === 1 ? 0 : 1 });
  };

  const handleAvailableReq = () => {
    setValue({ ...value, availableReq: value.availableReq === 1 ? 0 : 1 });
  };

  useEffect(() => {
    if (dataRequisite) {
      setValue({
        pan: dataRequisite?.pan,
        totalAmountReq: dataRequisite?.total_amount,
        usedTotalAmountReq: dataRequisite?.used_total_amount,
        isMain: dataRequisite?.is_main,
        availableReq: dataRequisite?.available,
      });
    }
  }, [dataRequisite]);

  const [{ loading }, executePost] = useAxios(
    {
      url: `/trader/requisite/profiles/edit`,
      method: "POST",
    },
    axiosConfig
  );
  const handleEdit = () => {
    executePost({
      data: {
        id: dataRequisite.id,
        pan: value?.pan,
        totalAmountReq: value?.totalAmountReq,
        usedTotalAmountReq: value?.usedTotalAmountReq,
        availableReq: value?.availableReq,
        is_main: value?.isMain,
      },
    })
      .then((data) => {
        toast.success("Успешно");
        refetch();
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };

  return (
    <div className={active ? "popup open" : "popup"}>
      <div className="popup__body" onClick={() => setActive(false)}>
        <div
          className="popup__content popup__device"
          onClick={(e) => e.stopPropagation(e)}
        >
          <div className="popup__header">
            <span>Редактировать реквизиты</span>
            <button onClick={() => setActive(false)}>
              <BackIcon />
            </button>
          </div>
          <div className="device__tab">
            <div className="input-block">
              <span>Номер карты</span>
              <div className="input-container">
                <input
                  value={value.pan}
                  type="text"
                  autoComplete="off"
                  placeholder="4200420042004200"
                  onChange={(e) => setValue({ ...value, pan: e.target.value })}
                />
                <CardMethodIcon />
              </div>
            </div>

            <div className="input-block">
              <span>Максимальная сумма на карте</span>
              <div className="input-container">
                <input
                  value={value.totalAmountReq}
                  type="number"
                  autoComplete="off"
                  placeholder="10000"
                  onChange={(e) =>
                    setValue({ ...value, totalAmountReq: e.target.value })
                  }
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <div className="input-block">
              <span>Использовано на карте</span>
              <div className="input-container">
                <input
                  value={value.usedTotalAmountReq}
                  type="number"
                  autoComplete="off"
                  placeholder="10000"
                  onChange={(e) =>
                    setValue({
                      ...value,
                      usedTotalAmountReq: e.target.value,
                    })
                  }
                />
                <RubbleIcon className="small-icon" />
              </div>
            </div>
            <div className="withdraw__switch switch-main">
              <span>Основной реквизит </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={value.isMain}
                  onChange={handleMain}
                />
                <span></span>
              </label>
            </div>
            <div className="withdraw__switch switch-main">
              <span>Доступность </span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={value.availableReq}
                  onChange={handleAvailableReq}
                />
                <span></span>
              </label>
            </div>
          </div>
          <div className="step__navigation">
            <button className="deviceBtn" onClick={handleEdit}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRequisiteEdit;
