import React, { useEffect, useState } from "react";

const DOTS = "...";

const Pagination = ({ page, setPage, count, total, pageCountQuery }) => {
  const pageCount = pageCountQuery || Math.ceil(total / count);

  const generatePaginationNumbers = () => {
    if (!pageCountQuery) {
      return [];
    }
    if (pageCount < 6)
      return Array.from({ length: pageCount }, (_, i) => i + 1);

    if (page <= 3) return [1, 2, 3, 4, DOTS, pageCount];
    if (page === 4) return [1, 2, 3, 4, 5, DOTS, pageCount];
    if (page > 4 && page <= pageCount - 3) {
      return [1, DOTS, page - 1, page, page + 1, DOTS, pageCount];
    }

    return [1, DOTS, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
  };

  const [arrOfCurrButtons, setArrOfCurrButtons] = useState(
    generatePaginationNumbers()
  );

  useEffect(() => {
    setArrOfCurrButtons(generatePaginationNumbers());
    // eslint-disable-next-line
  }, [page]);

  if (arrOfCurrButtons.length < 2) return null;

  return (
    <nav className="pagination">
      {arrOfCurrButtons.map((item, index) =>
        item ? (
          <button
            key={"pagination_" + index}
            disabled={item === DOTS}
            className={page === item ? "pagination-active" : ""}
            onClick={() => setPage(item)}
          >
            {item}
          </button>
        ) : null
      )}
    </nav>
  );
};

export default Pagination;
