import { useEffect, useState } from "react";
import { BackIcon, TelIcon } from "../Icons";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios from "axios-hooks";
import { axiosConfig } from "../../common/api";
import { toast } from "react-toastify";

const DeviceEdit = ({ active, setActive, device, refetch }) => {
  const [value, setValue] = useState({
    phone: device?.phonenumber,
  });

  useEffect(() => {
    setValue({
      phone: device?.phonenumber,
    });
  }, [device]);

  const [{ loading }, executePost] = useAxios(
    {
      url: `/trader/device/profiles/edit`,
      method: "POST",
    },
    axiosConfig
  );
  const handleEdit = () => {
    executePost({
      data: {
        id: device.id,
        phonenumber: value.phone,
      },
    })
      .then((data) => {
        toast.success("Успешно");
        refetch();
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };

  useGlobalScrollLock(active);
  return (
    <div className={active ? "popup open" : "popup"}>
      <div className="popup__body" onClick={() => setActive(false)}>
        <div
          className={`popup__content popup__device ${
            loading ? "__loading" : ""
          }`}
          onClick={(e) => e.stopPropagation(e)}
        >
          <div className="popup__header">
            <span>Редактировать устройство</span>
            <button onClick={() => setActive(false)}>
              <BackIcon />
            </button>
          </div>
          <div className="device__tab">
            <div className="input-block">
              <span>Номер телефона</span>
              <div className="input-container">
                <input
                  value={value.phone}
                  type="text"
                  autoComplete="off"
                  placeholder="+79991112233"
                  onChange={(e) =>
                    setValue({ ...value, phone: e.target.value })
                  }
                />
                <TelIcon />
              </div>
            </div>
          </div>
          <div className="step__navigation">
            <button className="deviceBtn" onClick={handleEdit}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceEdit;
