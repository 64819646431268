import { useEffect, useState } from "react";
import DeviceItem from "./DeviceItem";
import ModalDevice from "./modal/ModalDevice";
import ModalViewQrLinkDeviceProfile from "./modal/ModalViewQrLinkDeviceProfile";
import DeviceEdit from "./modal/ModalDeviceEdit";
import ModalBankAdd from "./modal/ModalBankAdd";
import ModalBankEdit from "./modal/ModalBankEdit";
import ModalRequisiteAdd from "./modal/ModalRequisiteAdd";
import ModalRequisiteEdit from "./modal/ModalRequisiteEdit";
import { $authHost, axiosConfig } from "../common/api";
import useAxios, { configure } from "axios-hooks";
import Spinner from "./spinner/Spinner";
import Pagination from "./Pagination";
const options = [
  { id: 1, value: 5 },
  { id: 2, value: 10 },
  { id: 3, value: 15 },
];

const optionsStatus = [
  { id: 1, value: null, name: "ALL" },
  { id: 2, value: 0, name: "Доступные" },
  { id: 3, value: 1, name: "Архивные" },
];
const TradeDevices = () => {
  const [active, setActive] = useState(false);
  const [activeQr, setActiveQr] = useState(false);
  const [activeDeviceEdit, setActiveDeviceEdit] = useState(false);
  const [activeBankAdd, setActiveBankAdd] = useState(false);
  const [activeBankEdit, setActiveBankEdit] = useState(false);
  const [activeRequisiteAdd, setActiveRequisiteAdd] = useState(false);
  const [activeRequisiteEdit, setActiveRequisiteEdit] = useState(false);
  const [dataSort, setDataSort] = useState({
    page: 1,
    count: 5,
    is_archive: 0,
  });
  const [dataDevice, setDataDevice] = useState(null);
  const [dataBank, setDataBank] = useState(null);
  const [dataRequisite, setDataRequisite] = useState(null);
  configure({ axios: $authHost });
  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/trader/device/profiles`,
      method: "POST",
    },
    axiosConfig
  );
  const refetch = () => {
    executePost({
      data: {
        page: dataSort.page,
        count: dataSort.count,
        is_archive: dataSort.is_archive,
      },
    }).catch((error) => {});
  };

  useEffect(() => {
    // Запускаем fetch при каждом изменении dataSort
    refetch();

    // Таймер для вызова executePost каждые 60 секунд
    const interval = setInterval(() => {
      refetch();
    }, 60000); // 60 секунд = 60000 миллисекунд

    // Очищаем таймер при изменении dataSort или размонтировании компонента
    return () => {
      clearInterval(interval);
      cancelRequest();
    };
  }, [dataSort]);

  const setPage = (value) => {
    setDataSort({ ...dataSort, page: value });
  };
  const setStatus = (e) => {
    setDataSort({ ...dataSort, is_archive: e.target.value });
  };
  const setCount = (e) => {
    setDataSort({ ...dataSort, count: e.target.value });
  };
  let pageCountQuery = Math.ceil(data?.countDeviceProfiles / dataSort.count);
  return (
    <div className="devices">
      <div className="main__head devices__head mb-16 ">
        <div className="text text_xl">Устройства</div>
        <button className="deviceBtn" onClick={() => setActive(true)}>
          Добавить устройство
        </button>
      </div>
      <div className="project-details__table-filter device__filter">
        <div>
          <span>Статус</span>
          <select onChange={setStatus}>
            {optionsStatus.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <span>Количество</span>
          <select onChange={setCount}>
            {options.map((item) => (
              <option key={item.id} value={item.value}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="devices__items">
        <div className="device__layout">
          <div className="device__header"></div>
          <div className="device__header">ID</div>
          <div className="device__header">Телефон</div>
          <div className="device__header">Пинг</div>
          <div className="device__header">Доступность</div>
          <div className="device__header">Действия</div>
        </div>
        {!data?.deviceProfiles ? (
          <div className="spinner__device">
            <Spinner />
          </div>
        ) : (
          <>
            {data?.deviceProfiles.map((device, index) => (
              <DeviceItem
                key={device.id}
                device={device}
                setDataBank={setDataBank}
                setDataDevice={setDataDevice}
                setActiveDeviceEdit={setActiveDeviceEdit}
                setActiveQr={setActiveQr}
                setActiveBankAdd={setActiveBankAdd}
                setActiveRequisiteAdd={setActiveRequisiteAdd}
                setActiveBankEdit={setActiveBankEdit}
                setActiveRequisiteEdit={setActiveRequisiteEdit}
                setDataRequisite={setDataRequisite}
                refetch={refetch}
              />
            ))}
          </>
        )}
      </div>
      {loading ? null : (
        <Pagination
          page={dataSort.page}
          setPage={setPage}
          pageCountQuery={pageCountQuery}
        />
      )}
      <ModalDevice active={active} setActive={setActive} refetch={refetch} />
      {dataDevice?.id && (
        <ModalViewQrLinkDeviceProfile
          active={activeQr}
          setActive={setActiveQr}
          deviceId={dataDevice?.id}
        />
      )}
      <DeviceEdit
        active={activeDeviceEdit}
        setActive={setActiveDeviceEdit}
        device={dataDevice}
        refetch={refetch}
      />
      <ModalBankAdd
        deviceId={dataDevice?.id}
        active={activeBankAdd}
        setActive={setActiveBankAdd}
        refetch={refetch}
      />
      <ModalBankEdit
        refetch={refetch}
        dataBank={dataBank}
        active={activeBankEdit}
        setActive={setActiveBankEdit}
      />
      <ModalRequisiteAdd
        bankId={dataBank?.id}
        refetch={refetch}
        active={activeRequisiteAdd}
        setActive={setActiveRequisiteAdd}
      />
      <ModalRequisiteEdit
        refetch={refetch}
        dataRequisite={dataRequisite}
        active={activeRequisiteEdit}
        setActive={setActiveRequisiteEdit}
      />
    </div>
  );
};
export default TradeDevices;
