import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import {
  ActionAddIcon,
  ActionArchiveIcon,
  ActionEditIcon,
  ActionInfoIcon,
  ArrowIcon,
  SelectIcon,
} from "./Icons";
import LinearProgressBar from "./linearprogressbar/LinearProgressBar";
import { formatFinance } from "../common/utils";
import { Tooltip } from "react-tippy";
import { generalBankList } from "../common/constants";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { toast } from "react-toastify";
import useDayjsFormat from "../hooks/useDayjsFormat";

const DeviceItem = ({
  device,
  setDataDevice,
  setActiveDeviceEdit,
  setActiveQr,
  setActiveBankAdd,
  setDataBank,
  setActiveRequisiteAdd,
  setActiveBankEdit,
  setDataRequisite,
  setActiveRequisiteEdit,
  refetch,
}) => {
  const { getFullDateMsk, getFullTimeMsk } = useDayjsFormat();
  configure({ axios: $authHost });
  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/trader/archive`,
      method: "POST",
    },
    axiosConfig
  );

  const handleArchive = (value) => {
    executePost({
      data: value,
    })
      .then(() => {
        refetch();
        toast.success("Успешно");
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };
  return (
    <Accordion
      allowMultiple={true}
      transition={true}
      transitionTimeout={200}
      className="device__item"
    >
      <AccordionItem
        className="deviceMain"
        initialEntered={false}
        header={
          <div className="device__layout deviceMain__header">
            <div className="device__status">
              <SelectIcon />
              <span>({device.BankProfiles.length})</span>
            </div>
            <div className="device__content">
              <span>{device.id}</span>
            </div>
            <div className="device__content">
              <span>{device.phonenumber}</span>
            </div>
            <div className="device__content">
              <span>
                {device.last_ping ? (
                  <>
                    {getFullTimeMsk(device.last_ping)} <br />
                    {getFullDateMsk(device.last_ping)}
                  </>
                ) : (
                  "-"
                )}
              </span>
            </div>
            <div className="device__content">
              <span>{device.is_archive === 0 ? "Доступен" : "Отключен"}</span>
            </div>
            <div className="device__actions">
              <Tooltip
                title="Добавить банк"
                position="bottom"
                trigger="mouseenter"
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation(e);
                    setDataDevice(device);
                    setActiveBankAdd(true);
                  }}
                >
                  <ActionAddIcon />
                </div>
              </Tooltip>
              <Tooltip
                title="Редактировать устройство"
                position="bottom"
                trigger="mouseenter"
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation(e);
                    setDataDevice(device);
                    setActiveDeviceEdit(true);
                  }}
                >
                  <ActionEditIcon />
                </div>
              </Tooltip>
              <Tooltip title="QR-code" position="bottom" trigger="mouseenter">
                <div
                  onClick={(e) => {
                    e.stopPropagation(e);
                    setDataDevice(device);
                    setActiveQr(true);
                  }}
                >
                  <ActionInfoIcon />
                </div>
              </Tooltip>
              <Tooltip title="Архив" position="bottom" trigger="mouseenter">
                <div
                  onClick={(e) => {
                    e.stopPropagation(e);
                    handleArchive({
                      id: device.id,
                      device: 1,
                    });
                  }}
                >
                  <ActionArchiveIcon />
                </div>
              </Tooltip>
            </div>
          </div>
        }
      >
        <Accordion
          allowMultiple={true}
          transition={true}
          transitionTimeout={200}
          className="device__bank"
        >
          <div className="device__layout">
            <div className="device__header"></div>
            <div className="device__header">ID</div>
            <div className="device__header">Банк</div>
            <div className="device__header">Лимит</div>
            <div className="device__header">Заполнено</div>
            <div className="device__header"></div>
          </div>
          {device.BankProfiles.map((bank) => (
            <AccordionItem
              key={bank.id}
              className="deviceBank__item"
              header={
                <div
                  className={`device__layout deviceBank__header ${
                    bank?.is_archive == 1 ? "__disabled" : ""
                  }`}
                >
                  <div className="device__status">
                    <ArrowIcon />
                    <span>({bank.RequisiteProfiles.length})</span>
                  </div>
                  <div className="device__content">
                    <span>{bank.id}</span>
                  </div>
                  <div className="device__content">
                    <span>{generalBankList[bank.bank]}</span>
                  </div>
                  <div className="device__content">
                    <span>
                      с {formatFinance(bank.min_amount)} до{" "}
                      {formatFinance(bank.max_amount)} ₽
                    </span>
                  </div>
                  <div className="device__content">
                    <div className="device__progress">
                      {formatFinance(bank.used_total_amount)} <br />
                      из {formatFinance(bank.total_amount)} ₽ <br />
                      {
                        <LinearProgressBar
                          currentValue={bank.used_total_amount}
                          limitValue={bank.total_amount}
                        />
                      }
                    </div>
                  </div>
                  <div className="device__actions">
                    <Tooltip
                      title="Добавить реквизиты"
                      position="bottom"
                      trigger="mouseenter"
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation(e);
                          setDataBank(bank);
                          setActiveRequisiteAdd(true);
                        }}
                      >
                        <ActionAddIcon />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Редактировать банк"
                      position="bottom"
                      trigger="mouseenter"
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation(e);
                          setDataBank(bank);
                          setActiveBankEdit(true);
                        }}
                      >
                        <ActionEditIcon />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Архив"
                      position="bottom"
                      trigger="mouseenter"
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation(e);
                          handleArchive({
                            id: bank.id,
                            bank: 1,
                          });
                        }}
                      >
                        <ActionArchiveIcon />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              }
            >
              <div className="device__layout">
                <div className="device__header"></div>
                <div className="device__header">ID</div>
                <div className="device__header">Реквизиты</div>
                <div className="device__header">Заполнено</div>
                <div className="device__header">Доступность</div>
                <div className="device__header"></div>
              </div>
              {bank?.RequisiteProfiles.map((requisite, index) => (
                <div
                  key={requisite.id}
                  className={`device__layout deviceRequisites ${
                    requisite?.is_archive == 1 ? "__disabled" : ""
                  }`}
                >
                  <div className="device__status"></div>
                  <div className="device__content">
                    <span>{requisite.id}</span>
                  </div>
                  <div className="device__content">
                    <span>{requisite.pan}</span>
                  </div>
                  <div className="device__content">
                    <div className="device__progress">
                      {formatFinance(requisite.used_total_amount)} <br />
                      из {formatFinance(requisite.total_amount)} ₽ <br />
                      {
                        <LinearProgressBar
                          currentValue={requisite.used_total_amount}
                          limitValue={requisite.total_amount}
                        />
                      }
                    </div>
                  </div>
                  <div className="device__content">
                    <span>
                      {requisite.available === 0 ? "Отключен" : "Доступен"}
                    </span>
                  </div>
                  <div className="device__actions">
                    <Tooltip
                      title="Редактировать реквизиты"
                      position="bottom"
                      trigger="mouseenter"
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation(e);
                          setDataRequisite(requisite);
                          setActiveRequisiteEdit(true);
                        }}
                      >
                        <ActionEditIcon />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Архив"
                      position="bottom"
                      trigger="mouseenter"
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation(e);
                          handleArchive({
                            id: requisite.id,
                            requisite: 1,
                          });
                        }}
                      >
                        <ActionArchiveIcon />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </AccordionItem>
          ))}
        </Accordion>
      </AccordionItem>
    </Accordion>
  );
};

export default DeviceItem;
