import { useState } from "react";
import { BackIcon, CardMethodIcon, RubbleIcon, UserIcon } from "../Icons";
import Select from "../select/select";
import { generalBankList, trafficDetailsScore } from "../../common/constants";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../../common/api";
import { toast } from "react-toastify";

const ModalBankAdd = ({ active, setActive, deviceId, refetch }) => {
  useGlobalScrollLock(active);
  configure({ axios: $authHost });

  const banksTypes = [];
  const trafficTypes = [];
  Object.keys(generalBankList).map((key) =>
    banksTypes.push({
      id: key,
      label: generalBankList[key],
    })
  );
  Object.keys(trafficDetailsScore).map((key) =>
    trafficTypes.push({
      id: key,
      label: trafficDetailsScore[key],
    })
  );
  const [step, setStep] = useState(0);
  const [bank, setBank] = useState({
    id: null,
    label: "Выберите",
    icon: null,
  });
  const [tier, setTier] = useState({
    id: null,
    label: "Выберите качество трафика",
    icon: null,
  });

  const [value, setValue] = useState({
    fio: "",
    bankId: "",
    minAmount: "",
    maxAmount: "",
    totalAmount: "",
    usedTotalAmount: "",
    sbpAvailable: "",
    availableBank: "",
    cardAvailable: "",
    enablePush: "",
    pan: "",
    totalAmountReq: "",
    usedTotalAmountReq: "",
    isMain: "",
    availableReq: "",
  });

  const handleNext = () => {
    setStep(step + 1);
  };
  const handlePrev = () => {
    setStep(step - 1);
  };
  const handleSbp = () => {
    setValue({ ...value, sbpAvailable: value.sbpAvailable === 1 ? 0 : 1 });
  };
  const handlePush = () => {
    setValue({ ...value, enablePush: value.enablePush === 1 ? 0 : 1 });
  };
  const handleCard = () => {
    setValue({ ...value, cardAvailable: value.cardAvailable === 1 ? 0 : 1 });
  };
  const handleMain = () => {
    setValue({ ...value, isMain: value.isMain === 1 ? 0 : 1 });
  };
  const handleAvailable = () => {
    setValue({ ...value, availableBank: value.availableBank === 1 ? 0 : 1 });
  };
  const handleAvailableReq = () => {
    setValue({ ...value, availableReq: value.availableReq === 1 ? 0 : 1 });
  };
  const [{ loading }, executePost] = useAxios(
    {
      url: `/trader/bank/profiles/create`,
      method: "POST",
    },
    axiosConfig
  );

  const handleAdd = () => {
    executePost({
      data: {
        device_profile_id: deviceId,
        fio: value.fio,
        bank: bank.id,
        min_amount: value.minAmount,
        max_amount: value.maxAmount,
        total_amount: value.totalAmount,
        used_total_amount: value.usedTotalAmount,
        sbp_available: value.sbpAvailable,
        available: value.availableBank,
        enable_push: value.enablePush,
        card_available: value.cardAvailable,
        tier: tier.id,
        pan: value.pan,
        totalAmountReq: value.totalAmountReq,
        usedTotalAmountReq: value.usedTotalAmountReq,
        availableReq: value.availableReq,
        is_main: value.isMain,
      },
    })
      .then((data) => {
        refetch();
        toast.success("Банк добавлен");
        setActive(false);
        setStep(0);
        setValue({
          phone: "",
          fio: "",
          bankId: "",
          minAmount: "",
          maxAmount: "",
          totalAmount: "",
          usedTotalAmount: "",
          sbpAvailable: "",
          availableBank: "",
          cardAvailable: "",
          enablePush: "",
          pan: "",
          totalAmountReq: "",
          usedTotalAmountReq: "",
          isMain: "",
          availableReq: "",
        });
      })
      .catch((err) => toast.error(err?.response?.data?.error));
  };
  return (
    <div className={active ? "popup open" : "popup"}>
      <div className="popup__body" onClick={() => setActive(false)}>
        <div
          className="popup__content popup__device"
          onClick={(e) => e.stopPropagation(e)}
        >
          <div className="popup__header">
            <span>Добавить банк</span>
            <button onClick={() => setActive(false)}>
              <BackIcon />
            </button>
          </div>
          <div className="step">
            <span className="step__item __active" onClick={() => setStep(0)}>
              Банк
            </span>
            <span className={`step__line ${step > 0 ? "__active" : ""}`}></span>
            <span
              className={`step__item ${step > 0 ? "__active" : ""}`}
              onClick={() => setStep(1)}
            >
              Реквизиты
            </span>
          </div>
          {step === 0 && (
            <div className="device__tab">
              <div className="input-block">
                <span>ФИО</span>
                <div className="input-container">
                  <input
                    value={value.phone}
                    type="text"
                    autoComplete="off"
                    placeholder="Иванов Иван Иваныч"
                    onChange={(e) =>
                      setValue({ ...value, fio: e.target.value })
                    }
                  />
                  <UserIcon />
                </div>
              </div>
              <div className="input-block">
                <span>Банк</span>
                <div className="input-container">
                  <Select value={bank} setValue={setBank} data={banksTypes} />
                </div>
              </div>
              <div className="input-block">
                <span>Мин платеж</span>
                <div className="input-container">
                  <input
                    value={value.minAmount}
                    type="number"
                    autoComplete="off"
                    placeholder="100"
                    onChange={(e) =>
                      setValue({ ...value, minAmount: e.target.value })
                    }
                  />
                  <RubbleIcon className="small-icon" />
                </div>
              </div>
              <div className="input-block">
                <span>Макс платеж</span>
                <div className="input-container">
                  <input
                    value={value.maxAmount}
                    type="number"
                    autoComplete="off"
                    placeholder="10000"
                    onChange={(e) =>
                      setValue({ ...value, maxAmount: e.target.value })
                    }
                  />
                  <RubbleIcon className="small-icon" />
                </div>
              </div>
              <div className="input-block">
                <span>Максимум на карту</span>
                <div className="input-container">
                  <input
                    value={value.totalAmount}
                    type="number"
                    autoComplete="off"
                    placeholder="10000"
                    onChange={(e) =>
                      setValue({ ...value, totalAmount: e.target.value })
                    }
                  />
                  <RubbleIcon className="small-icon" />
                </div>
              </div>
              <div className="input-block">
                <span>Использовано на карте</span>
                <div className="input-container">
                  <input
                    value={value.usedTotalAmount}
                    type="number"
                    autoComplete="off"
                    placeholder="10000"
                    onChange={(e) =>
                      setValue({ ...value, usedTotalAmount: e.target.value })
                    }
                  />
                  <RubbleIcon className="small-icon" />
                </div>
              </div>
              <div className="input-block">
                <span>Трафик</span>
                <div className="input-container">
                  <Select value={tier} setValue={setTier} data={trafficTypes} />
                </div>
              </div>
              <div className="withdraw__switch switch-main">
                <span>Включен сбп? </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={value.sbpAvailable}
                    onChange={handleSbp}
                  />
                  <span></span>
                </label>
              </div>
              <div className="withdraw__switch switch-main">
                <span>Пуши включены ловим их? </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={value.enablePush}
                    onChange={handlePush}
                  />
                  <span></span>
                </label>
              </div>
              <div className="withdraw__switch switch-main">
                <span>Карты доступны на этом банке или только сбп? </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={value.cardAvailable}
                    onChange={handleCard}
                  />
                  <span></span>
                </label>
              </div>
              <div className="withdraw__switch switch-main">
                <span>Доступность </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={value.availableBank}
                    onChange={handleAvailable}
                  />
                  <span></span>
                </label>
              </div>
            </div>
          )}
          {step === 1 && (
            <div className="device__tab">
              <div className="input-block">
                <span>Номер карты</span>
                <div className="input-container">
                  <input
                    value={value.pan}
                    type="text"
                    autoComplete="off"
                    placeholder="4200420042004200"
                    onChange={(e) =>
                      setValue({ ...value, pan: e.target.value })
                    }
                  />
                  <CardMethodIcon />
                </div>
              </div>

              <div className="input-block">
                <span>Максимальная сумма на карте</span>
                <div className="input-container">
                  <input
                    value={value.totalAmountReq}
                    type="number"
                    autoComplete="off"
                    placeholder="10000"
                    onChange={(e) =>
                      setValue({ ...value, totalAmountReq: e.target.value })
                    }
                  />
                  <RubbleIcon className="small-icon" />
                </div>
              </div>
              <div className="input-block">
                <span>Использовано на карте</span>
                <div className="input-container">
                  <input
                    value={value.usedTotalAmountReq}
                    type="number"
                    autoComplete="off"
                    placeholder="10000"
                    onChange={(e) =>
                      setValue({
                        ...value,
                        usedTotalAmountReq: e.target.value,
                      })
                    }
                  />
                  <RubbleIcon className="small-icon" />
                </div>
              </div>
              <div className="withdraw__switch switch-main">
                <span>Основной реквизит </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={value.isMain}
                    onChange={handleMain}
                  />
                  <span></span>
                </label>
              </div>
              <div className="withdraw__switch switch-main">
                <span>Доступность </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={value.availableReq}
                    onChange={handleAvailableReq}
                  />
                  <span></span>
                </label>
              </div>
            </div>
          )}
          <div className="step__navigation">
            {step === 0 && (
              <button className="deviceBtn" onClick={handleNext}>
                Далее
              </button>
            )}
            {step === 1 && (
              <>
                <button className="deviceBtn" onClick={handlePrev}>
                  Назад
                </button>
                <button className="deviceBtn" onClick={handleAdd}>
                  Добавить
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBankAdd;
