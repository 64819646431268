import { useEffect, useRef, useState } from "react";

const Select = ({ value, setValue, data, getIcon }) => {
  const [selectActive, setSelectActive] = useState(false);
  const selectRef = useRef(null); // Реф для обертки select

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setSelectActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Чистим слушатель, чтобы избежать утечек памяти
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);
  return (
    <div className={selectActive ? "select _active" : "select"} ref={selectRef}>
      <button
        onClick={() => {
          setSelectActive(!selectActive);
        }}
      >
        {getIcon && getIcon(value?.id)}
        <span>{value?.label}</span>
      </button>
      {selectActive && (
        <div className="select__items">
          {data.map((item) => (
            <div
              key={item.id}
              className="select__item"
              onClick={() => {
                setValue(item);
                setSelectActive(false);
              }}
            >
              {getIcon && getIcon(item?.id)}
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
